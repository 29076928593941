import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import "./style.css";

import logo from "./../../images/ss.jpg";
import qrCodeImg from "./../../images/qr.jpg";

import { API_URL } from "./../../global";
// import { stat } from "original-fs";

const axios = require("axios");

export default class BillManager extends Component {
    constructor(props) {
        super();

        this.state = {
            loadingCharges: 0,
            loadingChargesCheck: false,
            vehicleCharges: 0,
            vehicleChargesCheck: false,
            billId: null,
            partyId: 0,
            partyName: null,
            newPartyName: null,
            address: null,
            partyMobile: null,
            gst: "",
            billType: 1,
            productName: null,
            productId: 0,
            routeName: "",
            date: moment(new Date()).format("YYYY-MM-DD"),
            particularValue: null,
            particular: null,
            id: null,
            quantity: 0,
            rate: 0,
            amount: 0,
            itemList: [],
            addedItems: [],
            total: 0,
            balance: 0,
            sgst: 0,
            cgst: 0,
            igst: 0,
            advance: 0,
            nextServiceKm: null,
            dueDate: moment(new Date()).format("YYYY-MM-DD"),
            grandTotal: 0,
            printComponentRef: null,
            partyList: null,
            latestInsertId: 0,
            productList: [],
        };
    }

    getIdPartyList() {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `SELECT id, name, mobile, address FROM party;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                this.setState({ partyList: res.data });
            })
            .catch((err) => {
                console.log("id + name fetch error: ", err);
            });
    }

    getLatestId = (type) => {
        let url = API_URL;
        let query = `SELECT id FROM gstbill ORDER BY id DESC LIMIT 1;`;
        if (type === 2) {
            query = `SELECT id FROM nongstbill ORDER BY id DESC LIMIT 1;`
        }
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    billId: (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("latest id data fetch error: ", err);
            });
    };

    fetchProducts = () => {
        const query = `SELECT id,name from products WHERE status = 1 and type=1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                let _res = res.data.map((item) => {
                    return item.id + "," + item.name;
                });
                this.setState({ productList: _res });
                console.log(res.data)
            })
            .catch((err) => {
                console.log("productlist data fetch error: ", err);
            });
    };

    caluclateWeight = (field, value) => {
        if (field === "totalBoxes") {
            let weight = value * this.state.weightPerBox;
            this.setState({ totalBoxes: value });
            this.setState({ weight: weight });
        } else if (field === "weightPerBox") {
            let weight = this.state.totalBoxes * value;
            this.setState({ weightPerBox: value });
            this.setState({ weight: weight });
        }
    };

    calculateAmount = (field, value) => {
        if (field === "weight") {
            let amount = value * this.state.rate;
            this.setState({ weight: value });
            this.setState({ amount: amount });
        } else if (field === "rate") {
            let amount = this.state.weight * value;
            this.setState({ rate: value });
            this.setState({ amount: amount });
        }
    };

    calculateTaxes = () => {
        // console.log("calculateTaxes : >", this.state.total, {
        //     sgst: Number((this.state.total / 100) * 9).toFixed(2),
        //     cgst: Number((this.state.total / 100) * 9).toFixed(2),
        //     igst: Number((this.state.total / 100) * 18).toFixed(2),
        // })
        const total = this.state.total;
        this.setState(
            {
                sgst: Number((total / 100) * 9).toFixed(2),
                cgst: Number((total / 100) * 9).toFixed(2),
                igst: Number((total / 100) * 18).toFixed(2),
            },
            this.calculateGrandTotal
        );
    };

    calculateGrandTotal = () => {
        let grandTotal;
        if (this.state.billType === 1) {
            grandTotal = Number(this.state.total) + Number(this.state.igst)
        } else {
            grandTotal = Number(this.state.total)
        }

        if (this.state.loadingChargesCheck)
            grandTotal += Number(this.state.loadingCharges)
        if (this.state.vehicleChargesCheck)
            grandTotal += Number(this.state.vehicleCharges);
        this.setState({ grandTotal: grandTotal.toFixed(2) });
    };

    addItems = () => {
        if (!this.state.productId || !this.state.rate) return;
        // let items = this.state.itemList;
        let items = this.state.addedItems;
        console.log("added list : ", this.state.addedItems, this.state.productId)
        const ifExists = items.find(
            (item) => {return item.productId === this.state.productId}
        );
        console.log("ifExists : ", ifExists)
        if (ifExists) {
            items = items.map((item) => {
                if (item.productId === this.state.productId) {
                    return {
                        productId: this.state.productId,
                        productName: this.state.productName,
                        quantity: item.quantity + this.state.quantity,
                        amount: this.state.rate * (item.quantity + this.state.quantity),
                    };
                } else {
                    return item;
                }
            });
        } else {
            items.push({
                productId: this.state.productId,
                productName: this.state.productName,
                particular: this.state.particular,
                quantity: this.state.quantity,
                rate: this.state.rate,
                amount: this.state.rate * this.state.quantity,
            });
        }
        // items.push({
        //     particular: this.state.particular,
        //     mark: this.state.mark,
        //     totalBoxes: this.state.totalBoxes,
        //     weightPerBox: this.state.weightPerBox,
        //     weight: this.state.weight,
        //     rate: this.state.rate,
        //     amount: this.state.amount,
        // });

        this.setState({ addedItems: items });

        // update total & balance
        // let total = Number(this.state.total) + Number(this.state.amount);
        let total =
            Number(this.state.total) +
            Number(this.state.rate * this.state.quantity);
        this.setState({ total: total }, this.calculateTaxes);
        let balance = total + Number(this.state.advance);
        this.setState({ balance: balance });
        // this.calculateTaxes();
    };

    deleteItem = (index) => {
        // let itemList = this.state.itemList;
        let itemList = this.state.addedItems;

        // update total & balance
        let total = this.state.total - itemList[index]["amount"];
        let balance = total + Number(this.state.advance);
        this.setState({ total: total }, this.calculateTaxes);
        this.setState({ balance: balance });

        // remove element
        // let updatedList = itemList.filter((item, _index) => {
        //     if (index !== _index) return item;
        // });
        // this.setState({ itemList: updatedList });
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ addedItems: updatedList });
    };

    handleClear = () => {
        window.location.reload(false);
    };

    updateQuantity = (quantity, productId) => {
        let query;

        quantity = Math.ceil(quantity);
        query = `update products set quantity = quantity - ${this.state.quantity} where id = ${this.state.productId}`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                // console.log("quantity updated successfully");
                // setBillId(res.data.insertId);
                // insertBillList(res.data.insertId);
            })
            .catch((err) => {
                toast.error("Failed to Load Quantity ");
            });
    };

    insertBillList = () => {
        let url = API_URL;

        // 1.  insert into deliveryMemoList
        const query = `INSERT INTO billlist(billType, billId, partyId, particular,quantity, rate, amount, status) VALUES
        ${this.state.addedItems.map((item, index) => {
            return `(
                ${this.state.billType},
                ${this.state.billId},
                ${this.state.partyId},
                '${item.productName}',
                ${item.quantity}, 
                ${item.rate}, 
                ${item.amount},
                1
           )`
       })}`;
        console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    this.updateQuantity();
                    console.log("insert billlist successfull!");
                })
                .catch((err) => {
                    console.log("failed to insert billlist, error: ", err);
                });
    };

    insertLedgerRecord = () => {
        // 1.  insert into ledger
        const query = `INSERT INTO ledger(party_id, particular, total, memo_id) VALUES(${this.state.partyId}, 'reference bill id: ${this.state.latestInsertId}', ${this.state.total}, ${this.state.latestInsertId})`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert ledger successful");
                console.log("insert response: ", res.data.insertId);
            })
            .catch((err) => {
                console.log("failed to insert ledger, error: ", err);
            });
    };

    insertNewPartyAndSave = () => {
        const query = `INSERT INTO party (name, address, mobile) values("${this.state.newPartyName}", "${this.state.address}", "${this.state.partyMobile}")`;
        const data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert new party successful");
                console.log("insert response: ", res.data.insertId);
                this.setState({ partyId: res.data.insertId }, this.saveBill);
            })
            .catch((err) => {
                console.log("failed to insert new party, error: ", err);
            });
    };

    saveBill = () => {
        const newDate = moment(new Date()).format("YYYY-MM-DD");
        let query;
        if (this.state.billType === 1) {
            query = `INSERT INTO gstbill (partyId, total, gstin, paid, balance, date, status, vehicle_charges, loading_charges) values(
            ${this.state.partyId}, 
            ${this.state.grandTotal},
            '${this.state.gst}', 
            ${this.state.advance},
            ${this.state.grandTotal - this.state.advance}, 
            '${newDate}', 
            1,
            ${this.state.vehicleCharges},
            ${this.state.loadingCharges})`;
            console.log(query);

            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(API_URL, data)
                .then((res) => {
                    toast.success("Generated Bill successfully");
                    this.setState(
                        { billId: res.data.insertId },
                        this.insertBillList
                    );
                })
                .catch((err) => {
                    toast.error("Failed to Generate Bill ");
                });
        } else {
            query = `INSERT INTO nongstbill (partyId, total, paid, balance, date, status, vehicle_charges, loading_charges) values(
                ${this.state.partyId}, 
                ${this.state.grandTotal},
                ${this.state.advance},
                ${this.state.grandTotal - this.state.advance}, 
                '${newDate}', 
                1,
                ${this.state.vehicleCharges},
                ${this.state.loadingCharges})`;
            console.log(query);

            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(API_URL, data)
                .then((res) => {
                    toast.success("Generated Bill successfully");
                    this.setState(
                        { billId: res.data.insertId },
                        this.insertBillList
                    );
                })
                .catch((err) => {
                    toast.error("Failed to Generate Bill ");
                });
        }
    };

    handleSave = async (e) => {
        e.preventDefault();

        // check party already exists
        let partyId = this.state.partyId;
        if (partyId === null) {
            this.insertNewPartyAndSave();
        } else {
            this.saveBill();
        }
    };

    handleSavePrint = (e) => {
        console.log("in handle save print");
        // 1. handle save
        this.handleSave();
    };

    componentDidMount() {
        this.getLatestId();
        this.getIdPartyList();
        this.fetchProducts();
    }

    render() {
        return (
            <form className="mb-5" onSubmit={(e) => e.preventDefault()}>
                {/* Input Party Details */}

                <FormControl
                    style={{ minWidth: "250px" }}
                    className="mr-2 mb-2 smt-0"
                >
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={
                            this.state.partyList != null
                                ? this.state.partyList.map(
                                    (item) => item.id + ", " + item.name
                                )
                                : []
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // label="party name"
                                label="Party name"
                                variant="outlined"
                                size="small"
                                value={this.state.newPartyName}
                                onChange={(event) =>
                                    this.setState({
                                        newPartyName: event.target.value,
                                    })
                                }
                            />
                        )}
                        onChange={(event, value) => {
                            console.log(value);
                            if (value != null && value.length > 2) {
                                this.setState({
                                    partyId: value.split(", ")[0],
                                    partyName: value.split(", ")[1],
                                    address: this.state.partyList.find(
                                        (party) =>
                                            party.id == value.split(", ")[0]
                                    )?.address,
                                    partyMobile: this.state.partyList.find(
                                        (party) =>
                                            party.id == value.split(", ")[0]
                                    )?.mobile,
                                });
                            } else {
                                this.setState({
                                    partyId: null,
                                    partyName: "",
                                    address: "",
                                    partyMobile: ""
                                });
                            }
                        }}
                    />
                </FormControl>

                <TextField
                    id="custAddress"
                    label="Address"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.address || ""}
                    onChange={(e) => this.setState({ address: e.target.value })}
                    // required="true"
                    size="small"
                />

                <TextField
                    id="partyMobile"
                    label="Party Mobile"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.partyMobile || ""}
                    onChange={(e) =>
                        this.setState({ partyMobile: e.target.value })
                    }
                    // required="true"
                    size="small"
                />

                <FormControl
                    variant="filled"
                    className="mr-2 mb-2"
                    style={{ minWidth: "180px" }}
                    size="small"
                >
                    <InputLabel id="demo-simple-select-outlined-label">
                        Bill Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) =>
                            this.setState({ billType: e.target.value }, () => this.getLatestId(e.target.value))
                        }
                        name="billType"
                        value={this.state.billType}
                        size="small"
                    >
                        <MenuItem value={1}>GST</MenuItem>
                        <MenuItem value={2}>Non GST</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    id="gstin"
                    label="GSTIN"
                    variant="outlined"
                    className={"mr-2  " + (this.state.billType === 2 ? 'd-none' : '')}
                    value={this.state.gstin}
                    onChange={(e) => this.setState({ gst: e.target.value })}

                    size="small"
                />

                <TextField
                    id="advance"
                    label="Advance"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.advance}
                    onChange={(e) => this.setState({ advance: e.target.value })}
                    // required="true"
                    size="small"
                    type="number"
                    style={{ width: "150px" }}
                />


                {/* End of Input Party Details */}

                <hr />

                <Row>
                    <Col>
                        <FormControl
                            style={{ minWidth: "250px" }}
                            className="mr-2 mb-2 mt-1 smt-0"
                        >
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={
                                    this.state.productList != null
                                        ? this.state.productList.map(
                                            (item) => item
                                        )
                                        : []
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="party name"
                                        label="product"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value != null && value.length > 2)
                                        this.setState({
                                            productId: value.split(",")[0],
                                        });
                                    this.setState({
                                        productName: value.split(",")[1],
                                    });
                                    // this.setState({ particular: value});
                                }}
                            />
                        </FormControl>

                        <TextField
                            id="rate"
                            label="Rate"
                            variant="outlined"
                            className="mr-2 mt-1"
                            value={this.state.rate}
                            onChange={(e) =>
                                this.setState({ rate: e.target.value })
                            }
                            // required="true"
                            size="small"
                            type="number"
                        />

                        <TextField
                            id="quantity"
                            label="Quantity"
                            variant="outlined"
                            className="mr-2 mt-1"
                            value={this.state.quantity}
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                            // required="true"
                            size="small"
                            type="number"
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className="mt-1"
                            onClick={this.addItems}
                        // disabled={
                        //     !this.state.productName ||
                        //     !this.state.rate ||
                        //     !this.state.quantity
                        // }
                        >
                            Add
                        </Button>
                        <Checkbox checked={this.state.loadingChargesCheck} onChange={(e) => {
                            this.setState({
                                loadingChargesCheck: !this.state.loadingChargesCheck
                            })
                            this.calculateTaxes()
                        }} />
                        <TextField
                            id="Loading Charges"
                            label="Loading Charges"
                            variant="outlined"
                            className="mr-2 mt-1"
                            value={this.state.loadingCharges}
                            onChange={(e) =>
                                this.setState({ loadingCharges: e.target.value }, this.calculateTaxes)
                            }
                            // required="true"
                            size="small"
                            type="number"
                            disabled={!this.state.loadingChargesCheck}
                        />
                        <Checkbox checked={this.state.vehicleChargesCheck} onChange={(e) => {
                            this.setState({
                                vehicleChargesCheck: !this.state.vehicleChargesCheck
                            })
                            this.calculateTaxes()
                        }} />
                        <TextField
                            id="Vehicle Charges"
                            label="Vehicle Charges"
                            variant="outlined"
                            className="mr-2 mt-1"
                            value={this.state.vehicleCharges}
                            onChange={(e) =>
                                this.setState({ vehicleCharges: e.target.value }, this.calculateTaxes)
                            }
                            // required="true"
                            size="small"
                            type="number"
                            disabled={!this.state.vehicleChargesCheck}
                        />
                    </Col>
                </Row>

                <div
                    className="mt-1 p-2 measure"
                    ref={(el) => (this.printComponentRef = el)}
                >
                    <Row>
                        <Col md={8} className="mx-auto  ">
                            <Card className="mt-2 p-0">
                                <h6 className="text-center bg-info py-1">
                                    TAX INVOICE
                                </h6>

                                <Card.Header>
                                    <Row>
                                        <div className="col-3">
                                            <img
                                                src={logo}
                                                width="150"
                                                height="100"
                                                alt="logo"
                                            />
                                        </div>
                                        <div className="col-9 pb-4 mx-0">
                                            <div className="text-left  pb-0 mb-0">
                                                <b className="h2 " >
                                                    SS Industries 
                                                   <h3  className="mt-2">SSLITE</h3>
                                                </b>
                                                <hr style={{ width: '100%' }} />
                                                <p
                                                    className="text-start pb-0 mb-0"
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    SS industries , Jath MIDC , Jath Dist-Sangli Maharshtra Pincode-416404
                                                </p>
                                            </div>
                                        </div>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <p className="text-center">
                                            Phone No.: +91 95116 64552
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className="text-center">
                                                Website: www.ssindustriesjath@gmail.com
                                            </p>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p>
                                            Invoice No.{" "}
                                            <b>{this.state.billId}</b>
                                        </p>
                                        <p>
                                            Date{" "}
                                            <b>
                                                {moment(new Date()).format(
                                                    "D/M/YYYY"
                                                )}
                                            </b>
                                        </p>
                                    </span>
                                </Card.Header>
                                <Card.Body className="pb-3 mb-0">
                                    <Row>
                                        <div className="col-6 col-md-6">
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Party name:{" "}
                                                <b>
                                                    {this.state.partyName ||
                                                        this.state.newPartyName}
                                                </b>
                                            </h6>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Address:{" "}
                                                <b>{this.state.address}</b>
                                            </h6>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                GSTIN: <b>{this.state.gst}</b>
                                            </h6>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Mobile No.:{" "}
                                                <b>{this.state.partyMobile}</b>
                                            </h6>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                HSN CODE :{" "}
                                                <b>6810</b>
                                            </h6>
                                        </div>

                                    </Row>
                                </Card.Body>
                                <Card.Body className="m-0 pt-0">
                                    {/* Order overview */}
                                    <Tbl striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Particular</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        {this.state.addedItems.length > 0 ? (
                                            <tbody>
                                                {this.state.addedItems.map(
                                                    (item, index) => {
                                                        return (
                                                            <tr
                                                                key={item.productId}
                                                            >
                                                                <td>
                                                                    {
                                                                        item.productName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.rate}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.amount
                                                                    }
                                                                </td>
                                                                <td
                                                                    className="d-print-none"
                                                                    align="center"
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.deleteItem(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                                <br></br>
                                                {this.state.billType === 1 ? (
                                                    <div>
                                                        <tr>
                                                            <td colSpan="3">
                                                                Total amount
                                                                before tax
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .total
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">
                                                                SGST 9%
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .sgst
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">
                                                                CGST 9%
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .cgst
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">
                                                                IGST 18%
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .igst
                                                                }
                                                            </td>
                                                        </tr>
                                                    </div>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">
                                                            Total amount
                                                        </td>
                                                        <td colSpan="2">
                                                            {this.state.total}
                                                        </td>
                                                    </tr>
                                                )}
                                                {
                                                    this.state.loadingChargesCheck ?
                                                        <tr>
                                                            <td colSpan="3">
                                                                Loading Charges
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .loadingCharges
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        ""
                                                }
                                                {
                                                    this.state.vehicleChargesCheck ?
                                                        <tr>
                                                            <td colSpan="3">
                                                                Vehicle Charges
                                                            </td>
                                                            <td colSpan="2">
                                                                {
                                                                    this.state
                                                                        .vehicleCharges
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        ""
                                                }
                                                <tr>
                                                    <td colSpan="3">
                                                        <b>Grand Total</b>
                                                    </td>
                                                    <td colSpan="2">
                                                        <b>
                                                            {
                                                                this.state
                                                                    .grandTotal
                                                            }
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="6">
                                                        No items added
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </Tbl>
                                </Card.Body>
                                <Card.Footer className="pb-3 mb-0">
                                    <Row>

                                        <Col>
                                       
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
  <h6 style={{ textTransform: "capitalize" }}>
    <b style={{ fontSize: "Bold" }}>Term and Conditions</b>
  </h6>
  <p className="text-start pb-0 mb-0" style={{ fontSize: "14px" }}>
    1. Goods once sold will not be taken back
  </p>
  <p className="text-start pb-0 mb-0" style={{ fontSize: "14px" }}>
    2. Late payments will attract 2% interest per month
  </p>
  <p className="text-start pb-0 mb-0" style={{ fontSize: "14px" }}>
    3. We are not responsible for Breakage, Damage, or Loss in Transit
  </p>
  <p className="text-start pb-0 mb-0" style={{ fontSize: "14px" }}>
    4. Our responsibility towards goods ceases once they leave the factory premises
  </p>
</div>

                                              {/* <br></br> */}
                                              <br></br>
                                              <br></br>
                                              <br></br>
                                            
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                GSTIN No.: <b>27AEOFS7917N1Z1</b>
                                            </h6>
                                
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                State : Maharashtra Code: 27
                                            </h6>
                                        </Col>
                                        <Col style={{ height: "200px", textAlign: "center" }}>
                                            <img src={qrCodeImg} height="200" width="126" alt="qr code" />
                                        </Col>
                                        <Col>
                                        <div style={{  border: "1px solid #ccc", padding: "10px" }}>
  <h6
    style={{
      textTransform: "capitalize",
      margin: "0", // Remove default margin for h6
    }}
  >
    <b>Authorized Signature</b>
    <br></br>
                                                 <br></br>
                                                 <br></br>
                                                 <br></br>
                                                 <br></br>
                                                 <br></br>
                                                 
                                                 <br></br>
                                                 <br></br>
                                                 <br></br>
  </h6>
</div>

                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Bank Name <b>Sangli Urban Co-Op Bank LTD,Sangli</b>
                                            </h6>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                Bank A/c: <b>0220060150000001</b>
                                            </h6>
                                            <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                IFSC Code : HDFC0CSUCBL
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <div className="col-12" style={{ textAlign: "center" }}>developed by 5TechG Lab | M:7028828831</div>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="row">
                    <div className="col-10">
                        <ReactToPrint content={() => this.printComponentRef}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button
                                        onClick={handlePrint}
                                        className="mt-2 mr-1"
                                        color="primary"
                                        variant="contained"
                                        style={{ float: "right" }}
                                        disabled={
                                            (this.state.partyName ||
                                                this.state.newPartyName) &&
                                                this.state.address
                                                ? false
                                                : true
                                        }
                                    >
                                        Print
                                    </Button>
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>

                        <Button
                            className="mt-2 mr-1"
                            color="primary"
                            variant="contained"
                            style={{ float: "right" }}
                            // type="submit"
                            onClick={this.handleSave}
                            disabled={
                                (this.state.partyName ||
                                    this.state.newPartyName) &&
                                    this.state.address
                                    ? false
                                    : true
                            }
                        >
                            Save bill
                        </Button>
                        <Button
                            className="mt-2 mr-1"
                            color="primary"
                            variant="contained"
                            style={{ float: "right" }}
                            onClick={this.handleClear}
                        >
                            clear
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}
