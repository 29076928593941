import React, { Component } from "react";

import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
import moment from "moment/moment";
const axios = require("axios");

export class AddNewEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: 0,
            cubicMeter: 0,
            quantity: 0,
            wastage: 0,
            amount: 0,
            paid: 0,
            pending: 0,
            products: null,
            date: this.toDate(),
            per: 0.5,
            activePer: 0.5
        };
    }


    toDate = () => (new Date()).toISOString().split('T')[0];

    fetchProducts = () => {
        const query = `SELECT id, name FROM products where status = 1 and type = 1`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                let _res = res.data.map((item) => {
                    return [item.name, item.id];
                });
                this.setState({ products: _res });
            })
            .catch((err) => {
                console.log("product data fetch error: ", err);
            });
    };


    updateProductCount(diff, per) {
        let query = `UPDATE products SET quantity = quantity + ${this.state.quantity} WHERE id=${this.state.productId};`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        let product_update = axios
            .post(API_URL, data)
            .then((res) => {
                console.log("Product count updated successfully");
                return 0
            })
            .catch((err) => {
                console.log(err);
                return 0
            });

        query = `UPDATE details SET pending = pending + ${this.state.quantity * this.state.per};`;
        if (this.state.per !== this.state.activePer)
            query = `UPDATE details SET pending = pending + ${this.state.quantity * this.state.per}, current_per=${this.state.activePer};`;

        data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        let details_update = axios
            .post(API_URL, data)
            .then((res) => {
                console.log("Details updated successfully");
                return 0
            })
            .catch((err) => {
                console.log(err);
                return 0
            })

        // let particular = `${this.state.date} - ${this.state.products.filter(d => d[1] === this.state.productId)[0][0]} : ${this.state.quantity}`
        let particular = `${this.state.products.filter(d => d[1] === this.state.productId)[0][0]} x ${this.state.cubicMeter} : ${this.state.quantity} on ${moment(this.state.date).format("DD-MM-YYYY")}`
        query = `INSERT INTO ledger(party_id, particular, debit) VALUES(11, '${particular}', ${this.state.quantity * this.state.activePer});`;

        data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        let ledger_update = axios
            .post(API_URL, data)
            .then((res) => {
                console.log("ledger record added successfully");
                return 0
            })
            .catch((err) => {
                console.log(err);
                return 0
            });

        Promise.all([product_update, details_update, ledger_update]).then(res => {
            toast.success("Entry added successfully");
            setTimeout(() => {
                this.props.refreshLedger();
            }, 2000)
        })
    }

    handleAddSubmit(e) {
        e.preventDefault();
        if (this.state.productId === 0 || this.state.quantity === 0) return

        const query = `INSERT INTO daily(productId, cubicMeter,blocks, wastage, date, per) VALUES(${this.state.productId}, ${this.state.cubicMeter}, ${this.state.quantity}, ${this.state.wastage}, '${this.state.date}', ${this.state.activePer});`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.updateProductCount();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderMenu() {
        if (this.state.products != null) {
            return this.state.products.map((product, index) => {
                return <MenuItem value={product[1]} key={index}>{product[0]}</MenuItem>;
            });
        }
    }

    fetchDue = () => {
        const query = `SELECT  pending, current_per from details;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.setState({ pending: res.data[0].pending, per: res.data[0].current_per, activePer: res.data[0].current_per });
            })
            .catch((err) => {
                console.log("product data fetch error: ", err);
            });
    }

    fetchDetails = () => {

    }

    componentDidMount() {
        this.fetchProducts();
        this.fetchDue();
    }

    render() {
        return (
            <div className="row" style={{display: "block", margin: 0}}>
                <form autoComplete="off">
                    <div className="row ml-4 mt-4" style={{display: "block", margin: 0}}>
                        <FormControl
                            variant="filled"
                            className="mr-2 mb-2"
                            style={{ minWidth: "150px" }}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Product
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                onChange={(e) => {
                                    this.setState({
                                        productId: e.target.value,
                                    });
                                }}
                                name="productId"
                                value={this.state.productId}
                            >
                                {this.renderMenu()}
                            </Select>
                        </FormControl>
                        <TextField
                            id="Date"
                            label="Date"
                            variant="outlined"
                            type="date"
                            className="mr-2"
                            size="small"
                            value={this.state.date}
                            onChange={(e) =>
                                this.setState({ date: e.target.value })
                            }
                        />
                        <TextField
                            id="cubicMeter"
                            label="Cubic Meter"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            style={{ width: '7.5em' }}
                            value={this.state.cubicMeter}
                            onChange={(e) =>
                                this.setState({ cubicMeter: e.target.value })
                            }
                        />
                        <TextField
                            id="blocks"
                            label="Blocks"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            style={{ width: '7.5em' }}
                            value={this.state.quantity}
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                        />

                        <TextField
                            id="wastage"
                            label="wastage"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            style={{ width: '7.5em' }}
                            value={this.state.wastage}
                            onChange={(e) =>
                                this.setState({ wastage: e.target.value })
                            }
                        />

                        <TextField
                            id="per"
                            label="Per Block"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            inputProps={{
                                step: "0.1"
                            }}
                            size="small"
                            style={{ width: '7.5em' }}
                            value={this.state.activePer}
                            onChange={(e) =>
                                this.setState({ activePer: e.target.value })
                            }
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className="mb-3"
                            disabled={this.state.quantity === 0 || this.state.productId === 0}
                            onClick={(e) => this.handleAddSubmit(e)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            className="mb-3 ml-2"
                            onClick={this.props.refreshLedger}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>

                        <Button
                            color="primary"
                            variant="outlined"
                            className="float-right"
                            style={{height:'40px'}}
                        >
                            <h5>
                                Total balance:&nbsp; &nbsp;
                                <b>{this.state.pending}</b>
                            </h5>
                        </Button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
