import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox
} from "@material-ui/core";



import Autocomplete from "@material-ui/lab/Autocomplete";

import logo from "./../../images/ss.jpg";
import qrCodeImg from "./../../images/qr.jpg";

import { API_URL } from "./../../global";
// import { stat } from "original-fs";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const axios = require("axios");

const classes = {
    input: {
        textAlign: "center",

        // Centerize the placeholder text
        "&::placeholder": {
            textAlign: "center",
            opacity: 1,
        },
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    tableHeader: {
        textAlign: "center",
    },
};

export default class BillManager extends Component {
    constructor(props) {
        super();

        this.state = {
            rows: [
                { blockSize: "600X200x100 (4 inch)", units: "0", cubicMeters: "0",amount:"0" },
                { blockSize: "600x200x150 (6 inch)", units: "0", cubicMeters: "0" ,amount:"0"},
                { blockSize: "600x200x200 (8 inch)", units: "0", cubicMeters: "0",amount:"0" },
                { blockSize: "600x200x225 (9 inch)", units: "0", cubicMeters: "0",amount:"0" },
                { blockSize : "Chemical Bag", units : "0", cubicMeters :"0", amount : "0"}
            ],
            challan_id: "",
            date: moment(new Date()).format("D/M/YYYY"),
            customer_name: "",
            customer_address: "",
            vehicle_number: "",
            driver_name: "",
            driver_number: "",
            loadingCharges: 0,
            loadingChargesCheck: false,
            vehicleCharges: 0,
            vehicleChargesCheck: false,
            billId: null,
            partyId: 0,
            partyName: null,
            newPartyName: null,
            address: null,
            partyMobile: null,
            gst: "",
            billType: 1,
            productName: null,
            productId: 0,
            routeName: "",
            date: moment(new Date()).format("YYYY-MM-DD"),
            particularValue: null,
            particular: null,
            id: null,
            quantity: 0,
            rate: 0,
            amount: 0,
            itemList: [],
            addedItems: [],
            total: 0,
            balance: 0,
            sgst: 0,
            cgst: 0,
            igst: 0,
            advance: 0,
            nextServiceKm: null,
            dueDate: moment(new Date()).format("YYYY-MM-DD"),
            grandTotal: 0,
            printComponentRef: null,
            partyList: null,
            latestInsertId: 0,
            productList: [],
        };
        // 
        // 
        // 
    }

    handleUnitsChange = (index, value) => {
        const newRows = [...this.state.rows];
        newRows[index].units = value;
        this.setState({
            ...this.state,
            rows: newRows
        })
    };

    handleCubicMetersChange = (index, value) => {
        const newRows = [...this.state.rows];
        newRows[index].cubicMeters = value;
        this.setState({
            ...this.state,
            rows: newRows
        })
    };
    handleAmountChange = (index, value) => {
        const newRows = [...this.state.rows];
        newRows[index].amount = value;
        this.setState({
            ...this.state,
            rows: newRows
        })
    };

    handleClear = () => {
        window.location.reload(false);
    };

    handleSave = (e) => {
        let query;
        query = `INSERT INTO challan (id, date, customer_name, customer_address,
                type1, cubic1,amount1,  type2, cubic2, amount2, type3, cubic3, amount3,  type4, cubic4, amount4, type5, cubic5, amount5,
                vehicle_number, driver_name, driver_number ) values(
                ${this.state.challan_id}, 
                '${this.state.date}',
                '${this.state.customer_name}',
                '${this.state.customer_address}',
                '${this.state.rows[0].units}', ${this.state.rows[0].cubicMeters}, ${this.state.rows[0].amount},
                '${this.state.rows[1].units}', ${this.state.rows[1].cubicMeters}, ${this.state.rows[1].amount},
                '${this.state.rows[2].units}', ${this.state.rows[2].cubicMeters}, ${this.state.rows[2].amount},
                '${this.state.rows[3].units}', ${this.state.rows[3].cubicMeters}, ${this.state.rows[3].amount},
                '${this.state.rows[4].units}', ${this.state.rows[4].cubicMeters},${this.state.rows[4].amount},
                
                '${this.state.vehicle_number}',
                '${this.state.driver_name}',
             
                '${this.state.driver_number}');`
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("challan added successfully!")
                toast.success("challan added successfully!");
            })
            .catch((err) => {
                toast.error("Failed to Generate Bill ");
            });
    }

    handleSavePrint = (e) => {
        console.log("in handle save print");
        // 1. handle save
        this.handleSave();
    };

    getChallanId = () => {
        let url = API_URL;
        let query = `SELECT id FROM challan ORDER BY id DESC LIMIT 1;`
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data, res.data.length > 0 ? res.data[0]["id"] + 1 : 0);
                this.setState({
                    ...this.state,
                    challan_id: res.data.length > 0 ? res.data[0]["id"] + 1 : 0,
                });
            })
            .catch((err) => {
                console.log("latest id data fetch error: ", err);
            });
    }

    componentDidMount() {
        this.getChallanId()
    }

    render() {
        return (
            <form className="mb-5" onSubmit={(e) => e.preventDefault()}>
                <div
                    className="mt-1 p-2 measure"
                    ref={(el) => (this.printComponentRef = el)}
                >
                    <Row>
                        <Col md={8} className="mx-auto  ">
                            <Card className="mt-2 p-0" style={{ backgroundColor: "aliceblue", }}>
                                <h6 className="text-center py-1" style={{ backgroundColor: "green" }}>
                                    TAX INVOICE
                                </h6>

                                <Card.Header>
                                    <Row>
                                        <div className="col-6">
                                            <img
                                                src={logo}
                                                style={{ width: "90%", margin: "5px" }}
                                                alt="logo"
                                            />
                                            <p>
                                                Address: B-26, MIDC Jath, Sangli, Maharashtra.
                                            </p>
                                        </div>
                                        <div className="col-6 pb-4 mx-0 text-left" style={{ display: "grid", gridTemplateRows: "1fr 1fr 1fr" }}>
                                            <div className="text-left pb-0 mb-0">
                                                <b className="h3">
                                                    SSLITE

                                                    AAC BLOCK
                                                </b>
                                            </div>
                                            <div>
                                                Invoice No.{" "}
                                                <b>{this.state.challan_id}</b>
                                            </div>
                                            <div>
                                                Date{" "}
                                                <b>
                                                    {moment(new Date()).format(
                                                        "D/M/YYYY"
                                                    )}
                                                </b>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Header>
                                <Card.Body className="pb-3 mb-0">
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 10px 1fr", flexWrap: "wrap", padding: "10px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Customer Name"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "auto" }}
                                            onChange={e => this.setState({ ...this.state, customer_name: e.target.value })}
                                        />
                                        <div></div>
                                        <TextField
                                            id="outlined-basic"
                                            label="Customer Address"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "auto" }}
                                            onChange={e => this.setState({ ...this.state, customer_address: e.target.value })}
                                        />
                                    </div>
                                </Card.Body>
                                <Card.Body className="m-2 pt-1">
                                    <TableContainer style={{ marginTop: "30px" }} component={Paper}>
                                        <Table className={classes.table} ar1a-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        align="center"
                                                    >
                                                        Block Size
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        align="center"
                                                    >
                                                        No. of Units
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        align="center"
                                                    >
                                                        Cubic Meters
                                                    </TableCell>
                                                    
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        align="center"
                                                    >
                                                        Amount
                                                    </TableCell>
                                                
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.rows.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {row.blockSize}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="right">
                                                            <TextField
                                                                value={row.units}
                                                                onChange={(e) =>
                                                                    this.handleUnitsChange(index, e.target.value)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="right">
                                                            <TextField
                                                                value={row.cubicMeters}
                                                                onChange={(e) =>
                                                                    this.handleCubicMetersChange(index, e.target.value)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="right">
                                                            <TextField
                                                                value={row.amount}
                                                                onChange={(e) =>
                                                                    this.handleAmountChange(index, e.target.value)
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        TOTAL
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} align="right">
                                                        <TextField
                                                            value={this.state.rows.map(r => parseInt(r.units ? r.units : 0)).reduce((sum, e) => sum + e, 0)}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} align="right">
                                                        <TextField
                                                            value={this.state.rows.map(r => parseInt(r.cubicMeters ? r.cubicMeters : 0)).reduce((sum, e) => sum + e, 0)}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} align="right">
                                                        <TextField
                                                            value={this.state.rows.map(r => parseInt(r.amount ? r.amount : 0)).reduce((sum, e) => sum + e, 0)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 10px 1fr 10px 1fr", flexWrap: "wrap", marginTop: "30px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Vehicle No"
                                            variant="outlined"
                                            InputProps={{ classes: { input: classes.input } }}
                                            style={{ width: "100%", margin: "10px auto" }}
                                            onChange={e => this.setState({ ...this.state, vehicle_number: e.target.value })}
                                        />
                                        <div></div>
                                        <TextField
                                            id="outlined-basic"
                                            label="Driver's Name"
                                            variant="outlined"
                                            InputProps={{ classes: { input: classes.input } }}
                                            style={{ width: "100%", margin: "10px auto" }}
                                            onChange={e => this.setState({ ...this.state, driver_name: e.target.value })}
                                        />
                                        <div></div>
                                        <TextField
                                            id="outlined-basic"
                                            label="Drivers's Mobile No."
                                            variant="outlined"
                                            InputProps={{ classes: { input: classes.input } }}
                                            style={{ width: "100%", margin: "10px auto" }}
                                            onChange={e => this.setState({ ...this.state, driver_number: e.target.value })}
                                        />
                                    </div>
                                    {/* <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "30px",
                                        }}
                                    >
                                        <p style={{ marginTop: "auto" }}>Signature Of Driver:</p>

                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            InputProps={{ classes: { input: classes.input } }}
                                            style={{ width: 300, margin: "10px auto 0 0" }}
                                        />
                                    </div> */}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "30px",
                                        }}
                                    >
                                        <p style={{ marginTop: "auto" }}>Signature Of Supervisor :</p>

                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            InputProps={{ classes: { input: classes.input } }}
                                            style={{ width: 300, margin: "10px auto 0 0" }}
                                        />
                                    </div>
                                    {/* <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "30px",
                                        }}
                                    >
                                        <p style={{ marginTop: "auto" }}>Signature Of Customer :</p>

                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            InputProps={{ classes: { input: classes.input } }}
                                            style={{ width: 300, margin: "10px auto 0 0" }}
                                        />
                                    </div> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="row">
                    <div className="col-10">
                        <ReactToPrint content={() => this.printComponentRef}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button
                                        onClick={handlePrint}
                                        className="mt-2 mr-1"
                                        color="primary"
                                        variant="contained"
                                        style={{ float: "right" }}
                                        disabled={
                                            this.state.customer_name &&
                                                this.state.customer_address &&
                                                this.state.vehicle_number &&
                                                this.state.driver_name &&
                                                this.state.driver_number 
                                                // this.state.amount
                                                ? false
                                                : true
                                        }
                                    >
                                        Print
                                    </Button>
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>

                        <Button
                            className="mt-2 mr-1"
                            color="primary"
                            variant="contained"
                            style={{ float: "right" }}
                            // type="submit"
                            onClick={this.handleSave}
                            disabled={
                                this.state.customer_name &&
                                    this.state.customer_address &&
                                    this.state.vehicle_number &&
                                    this.state.driver_name &&
                                    this.state.driver_number 
                                    // this.state.amount
                                    ? false
                                    : true
                            }
                        >
                            Save bill
                        </Button>
                        <Button
                            className="mt-2 mr-1"
                            color="primary"
                            variant="contained"
                            style={{ float: "right" }}
                            onClick={this.handleClear}
                        >
                            clear
                        </Button>
                    </div>
                </div>
                <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={5000}
            />
            </form>
        );
    }
}
